import * as React from "react";
import { Seo } from "../components/seo";
import { Link } from "gatsby";
import { Layout } from "../layout";

import CompanyImg from "../assets/img/bg-company.jpeg";
import GreetingsImg from "../assets/img/IMG_0255.jpeg";

import CEOName from "../assets/svg/ceo-name.svg";

const CompanyPage = () => {
  return (
    <Layout>
      <Seo title="会社概要" />
      <main>
        {/* <!-- ヘッダータイトル --> */}
        <section className="mb-6">
          <div
            style={{ backgroundImage: `url(${CompanyImg})` }}
            className="
            relative
            flex
            items-center
            justify-center
            2xl:-mt-2
            w-screen
            h-40vh
            bg-cover bg-center
            overflow-hidden
            md:h-60vh
          "
          >
            <h1 className="flex flex-col items-center justify-center tracking-widest">
              <span className="text-white text-2xl md:text-4xl">COMPANY</span>
              <span className="mt-2 text-white text-xs md:text-base">
                会社概要
              </span>
            </h1>
            <div>
              <svg
                className=" wave absolute bottom-0 inset-x-0 -mb-1 w-200vw text-white fill-current
              "
                viewBox="0 0 1440 64"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M360 19.483C240 -6.49433 120 -6.49433 0 19.483V63.5H1440V19.483C1320 45.4603 1200 45.4603 1080 19.483C960 -6.49433 840 -6.49433 720 19.483C600 45.4603 480 45.4603 360 19.483Z" />
              </svg>
            </div>
          </div>
        </section>
        {/* <!-- 現在ページ --> */}
        <section className="mb-6 mx-auto px-6 md:px-10 lg:max-w-5xl lg:max-w-5xl">
          <div className="flex items-center justify-end">
            <div className="flex items-center justify-center">
              <span className="mr-2 text-primary-blue text-xs md:text-sm">
                <Link to="/">TOP</Link>
              </span>
              <span className="mr-2 text-xs md:text-sm">/</span>
              <span className="text-xs md:text-sm">会社概要</span>
            </div>
          </div>
        </section>
        {/* <!-- ご挨拶 --> */}
        <section className="mb-12 mx-auto px-6 md:mb-24 md:px-10 lg:mb-32 lg:max-w-5xl">
          <div className="flex flex-col items-center justify-center mb-12 lg:mb-20">
            <div className="mb-2 md:mb-4">
              <h2 className="text-primary-blue md:text-xl">ご挨拶</h2>
            </div>
            <div className="w-8 h-0.5 bg-primary-blue md:w-12"></div>
          </div>
          <div className="relative flex flex-col">
            <div
              style={{ backgroundImage: `url(${GreetingsImg})` }}
              className="mx-auto w-full h-48 bg-cover bg-center rounded md:h-104 lg:w-4/5
            "
            ></div>
            <div className="p-4 bg-white md:p-8 lg:w-4/5 mx-auto">
              <p
                className=" mb-6 text-xs tracking-widest leading-5 md:text-sm md:leading-7
              "
              >
                店舗・ショールーム・各種商業施設の設計施工において、要求されるテーマは無限です。
                明確なコンセプト、注目を集める外観、時代のトレンド、効果的な動線の設定、周囲の景観とのマッチングなどなど...
                ファインはトータルプランナーとして、クライアントのさまざまな要望にキメ細かく対応。
                企画から設計施工・内装工事まで、幅広い分野で、業務を推進しています。
              </p>
              <h2 className="flex items-center justify-end">
                <span className="mr-2 text-sm md:text-base">代表取締役</span>
                <img src={CEOName} style={{ height: 35 }} alt="ceo-name" />
              </h2>
            </div>
          </div>
        </section>
        {/* <!-- 企業概要 --> */}
        <section className="mb-12 px-6 md:mb-24 md:px-10 lg:mb-32">
          <div className="flex flex-col items-center justify-center mb-12 lg:mb-20">
            <div className="mb-2 md:mb-4">
              <h2 className="text-primary-blue md:text-xl">会社概要</h2>
            </div>
            <div className="w-8 h-0.5 bg-primary-blue md:w-12"></div>
          </div>
          <div className="flex flex-col mx-auto md:max-w-xl">
            <div className="flex flex-wrap items-center justify-between mb-4 md:mb-6">
              <div className="mb-2 w-full md:mb-0 md:w-1/3">
                <h2 className="text-sm md:text-base">会社名</h2>
              </div>
              <div className="w-full md:w-2/3">
                <p className="text-sm md:text-base">株式会社ファイン</p>
              </div>
            </div>
            <div className="flex flex-wrap items-center justify-between mb-4 md:mb-6">
              <div className="mb-2 w-full md:mb-0 md:w-1/3">
                <h2 className="text-sm md:text-base">設立</h2>
              </div>
              <div className="w-full md:w-2/3">
                <p className="text-sm md:text-base">平成1年11月13日</p>
              </div>
            </div>
            <div className="flex flex-wrap items-center justify-between mb-4 md:mb-6">
              <div className="mb-2 w-full md:mb-0 md:w-1/3">
                <h2 className="text-sm md:text-base">代表者名</h2>
              </div>
              <div className="w-full md:w-2/3">
                <p className="text-sm md:text-base">代表取締役 山田 義行</p>
              </div>
            </div>
            <div className="flex flex-wrap items-center mb-4 md:mb-6">
              <div className="mb-2 w-full md:mb-6 md:w-1/3">
                <h2 className="text-sm">事業内容</h2>
              </div>
              <div className="w-full md:w-2/3">
                <p className="text-sm md:text-base">
                  ・商業施設の設計・施工・監理
                  <br />
                  ・店舗及びショールームの販促什器の製造・販売
                  <br />
                  ・その他内装仕上げ工事に関する全般
                  <br />
                  ≪建築業の許可票≫
                  <br />
                  大阪府知事許可（般-30）第121292号
                  <br />
                </p>
              </div>
            </div>
            <div className="flex flex-wrap items-center mb-4 md:mb-6">
              <div className="mb-2 w-full md:mb-6 md:w-1/3">
                <h2 className="text-sm">所在地</h2>
              </div>
              <div className="w-full md:w-2/3">
                <p className="text-sm md:text-base">
                  <span className="font-semibold">本社</span>
                  <span>〒581-0041　大阪府八尾市北木ノ本3-36</span>
                  <span>
                    TEL
                    <Link className="mx-2 text-primary-blue" to="/">
                      072-993-8253　
                    </Link>
                    FAX
                    <Link to="/" className="mx-2">
                      072-993-8622
                    </Link>
                  </span>
                  <span className="mt-1 font-semibold md:mt-2">東京事務所</span>
                  <span>〒154-0002　東京都世田谷区下馬1-34-12-B104</span>
                  <span>
                    TEL
                    <Link className="mx-2 text-primary-blue" to="/">
                      03-6450-7910　
                    </Link>
                    FAX
                    <Link to="/" className="mx-2">
                      03-6450-7911
                    </Link>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default CompanyPage;
